import React from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './header/navbar';
import Signin from './Signin/signin';
import Homepage from './homepage/homepage';
import BlogsMain from './blogs/blogsMain';
import BlogsSub from './blogs/blogsSub';
import Page from './Pages/page';
import Thankyou from './thank-you/thank-you';
import Privacy_policy from './privacypolicy/Privacy&policy';
import Terms_conditions from './privacypolicy/Terms&Conditions';
import { Helmet } from 'react-helmet';
import { useGetHeadScriptQuery } from './slices/headScriptSlice';
import { Site } from './constants';

export default function AllRoutes() {
 const {pathname}=useLocation()
//  console.log(pathname)
const {data,isFetching,refetch}=useGetHeadScriptQuery(Site)

// const googleTag='AW-16707530739'
{/* <script>
 {` window.dataLayer = window.dataLayer || [];
  function gtag(){
    dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${googleTag}');`}
</script> */}
{/* <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTag}`}></script> */}
    return (
    <>
    <Helmet>
      {data&&data[0]&&data.map((val,idx)=>
val.type==='Single'?    <script key={idx} async src={val.script}></script>
:
<script key={idx}>
 {val.script}
</script>
      )}

    </Helmet>
    
    <Routes>
      
{pathname==='/'? <Route path='/' element={<BlogsMain />} />:    <Route path='/:id' element={<Page />} />
}    <Route path='/blog/:id' element={<BlogsSub />} />
<Route  path='/thank-you' element={<Thankyou />} />

    <Route path='/fms-gate' element={<Signin />} />
    <Route  path='/privacy-policy' element={<Privacy_policy />} />
    <Route  path='/terms-and-conditions' element={<Terms_conditions />} />

    </Routes>
    
    </>
  )
}
