import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter,  } from 'react-router-dom';

import AllRoutes from './AllRoutes';
import ScrollT from './STTP/scroll_Top';

function App() {


  return (
  
  <BrowserRouter>
    {/* <Navbar /> */}
  
<AllRoutes />
    <ToastContainer />
    <ScrollT />
    </BrowserRouter>
  );
}

export default App;
