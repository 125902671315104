import { Site } from "../constants";
import { apiSlice } from "./apiSlice";

const headScriptSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
      getHeadScript:builder.query({
        query:(data)=>({
            url:`head/${data}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      
      createHeadScript:builder.mutation({
        query:(data)=>({
            method:'POST',
            body:data,
            url:`head/${Date.toString()}`
        })
      }),
      editHeadScript:builder.mutation({
        query:(data)=>({
            method:'PUT',
            body:data,
            url:`head/${data.id}`
        })
      }),
     
   
    })
})


export const {useCreateHeadScriptMutation,useEditHeadScriptMutation,useGetHeadScriptQuery}=headScriptSlice