import React, { useState } from 'react'
import { MyEditorS, MyEditorS3 } from '../editor/editor'
import { Chip, CircularProgress, Grid, Typography } from '@mui/material'
import { ChevronLeftRounded } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useCreatePrivacyMutation, useEditPrivacyMutation, useGetPrivacyQuery } from '../slices/privacySlice'

export default function Privacy(props) {
const {site1}=props
const {data,isFetching,refetch}=useGetPrivacyQuery(site1._id)

const [createPrivacy,{isLoading:isLoadingC}]=useCreatePrivacyMutation()
const [editPrivacy,{isLoading:isLoadingE}]=useEditPrivacyMutation()
    const [editor,setEditor]=useState(' ')
    const [privacy,setPrivacy]=useState('')


    
    const quillChange=(val)=>{

        // console.log(val)
        setEditor(val)
setPrivacy(val)        
       }
// console.log(editor)
       const createClicked=async()=>{
if(editor.length>50){

const data1={
    site:site1._id,
    privacy:privacy
}
const privacy1=await createPrivacy(data1).unwrap()

if(privacy1.message){
    refetch()
    toast.success(privacy1.message)
    
}

}

else{
    toast.error('Please provide valid privacy policy')
}
       }
       const editClicked=async()=>{
        if(editor.length>50){
            const data1={
                id:data._id,
                privacy:privacy
            }
            const privacy1=await editPrivacy(data1).unwrap()
            
            if(privacy1.message){
                refetch()
                toast.success(privacy1.message)
                
            }
        }
        
        else{
            toast.error('Please provide valid privacy policy')
        }
       }
    
  return (

    <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
        <Grid container  sm={12} xs={12}>
    <Grid item sm={2} xs={2}>
    <ChevronLeftRounded  onClick={()=>props.changeF()} style={{cursor:'pointer',color:'#48a3b8',fontSize:36,paddingLeft:10}} />
    </Grid>
    <Grid item sm={8} xs={8}>
    <Typography style={{fontSize:20,fontWeight:'700',color:'#48a3b8'}}>
            {site1.name} / Privacy Policy
        </Typography>
    </Grid>
   
 
            </Grid>
          
 {isFetching?<div style={{alignContent:'center',alignItems:'center',paddingTop:'10vh',textAlign:'-webkit-center'}}>
    <CircularProgress />  </div>:
    
    <div>
    <MyEditorS3  quillChange={quillChange} editor={data&&data.privacy?data.privacy:editor}/>
    <div style={{width:'100%',textAlign:'center'}}>

{data?    <Chip disabled={isLoadingE}  onClick={()=>editClicked()} label={isLoadingE?"Saving":'Save'}  className='chip_new'/>
:<Chip  disabled={isLoadingC} label={isLoadingC?'Creating':'Create'}   onClick={()=>createClicked()} className='chip_new'/>}    </div>
    </div>}

             </Grid> 

  )
}
