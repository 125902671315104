import React, { useState } from 'react'
import { MyEditorS, MyEditorS2 } from '../editor/editor'
import { Chip, CircularProgress, Grid, Typography } from '@mui/material'
import { ChevronLeftRounded } from '@mui/icons-material'
import { useCreateTermMutation, useEditTermMutation, useGetTermQuery } from '../slices/termsSlice'
import { toast } from 'react-toastify'

export default function Terms(props) {
const {site1}=props
const {data,isFetching,refetch}=useGetTermQuery(site1._id)

const [createTerm,{isLoading:isLoadingC}]=useCreateTermMutation()
const [editTerm,{isLoading:isLoadingE}]=useEditTermMutation()
    const [editor,setEditor]=useState(' ')
    const [term,setTerm]=useState('')


    
    const quillChange=(val)=>{

        // console.log(val)
        setEditor(val)
setTerm(val)        
       }
// console.log(editor)
       const createClicked=async()=>{
if(editor.length>50){

const data1={
    site:site1._id,
    term:term
}
const term1=await createTerm(data1).unwrap()

if(term1.message){
    refetch()
    toast.success(term1.message)
    
}

}

else{
    toast.error('Please provide valid terms')
}
       }
       const editClicked=async()=>{
        if(editor.length>50){
            const data1={
                id:data._id,
                term:term
            }
            const term1=await editTerm(data1).unwrap()
            
            if(term1.message){
                refetch()
                toast.success(term1.message)
                
            }
        }
        
        else{
            toast.error('Please provide valid terms')
        }
       }
    
  return (

    <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
        <Grid container  sm={12} xs={12}>
    <Grid item sm={2} xs={2}>
    <ChevronLeftRounded  onClick={()=>props.changeF()} style={{cursor:'pointer',color:'#48a3b8',fontSize:36,paddingLeft:10}} />
    </Grid>
    <Grid item sm={8} xs={8}>
    <Typography style={{fontSize:20,fontWeight:'700',color:'#48a3b8'}}>
            {site1.name} / Terms & Conditions
        </Typography>
    </Grid>
   
 
            </Grid>
          
 {isFetching?<div style={{alignContent:'center',alignItems:'center',paddingTop:'10vh',textAlign:'-webkit-center'}}>
    <CircularProgress />  </div>:
    
    <div>
    <MyEditorS2  quillChange={quillChange} editor={data&&data.term?data.term:editor}/>
    <div style={{width:'100%',textAlign:'center'}}>

{data?    <Chip disabled={isLoadingE}  onClick={()=>editClicked()} label={isLoadingE?"Saving":'Save'}  className='chip_new'/>
:<Chip  disabled={isLoadingC} label={isLoadingC?'Creating':'Create'}   onClick={()=>createClicked()} className='chip_new'/>}    </div>
    </div>}

             </Grid> 

  )
}
