import React, { useState } from 'react'
import { MyEditorS, MyEditorS2 } from '../editor/editor'
import { Accordion, AccordionDetails, AccordionSummary, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material'
import { ChevronLeftRounded, CloseRounded, ExpandMore } from '@mui/icons-material'
import { useCreateTermMutation, useEditTermMutation, useGetTermQuery } from '../slices/termsSlice'
import { toast } from 'react-toastify'
import { useCreateHeadScriptMutation, useEditHeadScriptMutation, useGetHeadScriptQuery } from '../slices/headScriptSlice'

export default function Scripts(props) {
const {site1}=props
const {data,isFetching,refetch}=useGetHeadScriptQuery(site1._id)
const [open,setOpen]=useState(false)
const [createHeadScript,{isLoading:isLoadingC}]=useCreateHeadScriptMutation()
const [editHeadScript,{isLoading:isLoadingE}]=useEditHeadScriptMutation()
  
const [data1,setData1]=useState({})

    const handleClose=()=>{
setOpen(false)
setData1({...data1,isActive:'',script:'',type:'',id:''})
    }

// console.log(editor)
       const createScript=async()=>{
if(data1.script&&data1.type){

const data2={
    site:site1._id,
    head:data1.script,
    type:data1.type,
    isActive:data1.isActive?true:false
}
const script1=await createHeadScript(data2).unwrap()

if(script1.message){
    refetch()
    toast.success(script1.message)
    handleClose()

    
}

}

else{
    toast.error('Please provide valid values')
}
       }
       const editScript=async()=>{
        if(data1.script&&data1.type){
        
        const data2={
          id:data1.id,
            site:site1._id,
            head:data1.script,
            type:data1.type,
            isActive:data1.isActive?true:false
        }
        const script1=await editHeadScript(data2).unwrap()
        
        if(script1.message){
            refetch()
            toast.success(script1.message)
            handleClose()
            
        }
        
        }
        
        else{
            toast.error('Please provide valid values')
        }
               }
       const Edit=(val)=>{
        setData1({...data1,id:val._id,script:val.script,type:val.type,isActive:val.isActive})
        setOpen(true)
       }
    
  return (

    <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
        <Grid container  sm={12} xs={12}>
    <Grid item sm={2} xs={2}>
    <ChevronLeftRounded  onClick={()=>props.changeF()} style={{cursor:'pointer',color:'#48a3b8',fontSize:36,paddingLeft:10}} />
    </Grid>
    <Grid item sm={8} xs={8}>
    <Typography style={{fontSize:20,fontWeight:'700',color:'#48a3b8'}}>
            {site1.name} / Scripts
        </Typography>
    </Grid>
    <Grid item sm={2} xs={2}>
    <Chip  onClick={()=>setOpen(true)} className='chip_new' label={'Create new script'} />
    </Grid>
   
 
            </Grid>
          
 {isFetching?<div style={{alignContent:'center',alignItems:'center',paddingTop:'10vh',textAlign:'-webkit-center'}}>
    <CircularProgress />  </div>:
    
    <div>

<Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        { <DialogTitle style={{fontSize:22,fontWeight:'bold',color:'#48a3b8'}}>{`Create Script`}</DialogTitle>
}
 <IconButton
          aria-label="close"
          onClick={()=>handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseRounded />
        </IconButton>
        

        <DialogContent>

<Grid container>

<Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
<FormGroup style={{float:'right'}}>
      <FormControlLabel style={{fontWeight:'600'}} control={<Switch  onChange={(e)=>setData1({...data1,isActive:e.target.checked})} checked={data1.isActive?true:false} color='warning'  style={{color:'#CC0000' }} />} label="Script active" />
      </FormGroup>
<FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Script Type</FormLabel>
      <RadioGroup
      onChange={(e)=>setData1({...data1,type:e.target.value})}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="Single" control={<Radio checked={data1.type==='Single'?true:false} />} label="Single" />
        <FormControlLabel value="Multiline" control={<Radio  checked={data1.type==='Multiline'?true:false}/>} label="Multiline" />
      
      </RadioGroup>
    </FormControl>
        
                </Grid> 

            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={data1.script}  onChange={(e)=>setData1({...data1,script:e.target.value})} style={{width:'100%'}}  variant={'outlined'} 
        focused
         label='Script*' multiline placeholder='Enter script' />
        
                </Grid> 

              

        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        {
          data1.id?
          <Chip   onClick={()=>editScript()}  label={isLoadingE?"Saving":'Save Script'}  className='chip_new'/>:
        <Chip   onClick={()=>createScript()}  label={isLoadingC?"Creating":'Create Script'}  className='chip_new'/>



}
        </div>
        </Grid>
        </DialogContent>
        
       
      </Dialog>
      <Grid item justifyItems={'center'} sm={12} xs={12}>
        {data&&data[0]?data.map((val,idx)=>  <Accordion style={{width:'90%'}} key={idx}>
         <AccordionSummary

         style={{margin:'1vh 0px'}}
           expandIcon={<ExpandMore />}
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
<Typography style={{width:'80%',textAlign:'left'}}>Script {idx+1}</Typography>
      {<div style={{width:'20%',textAlign:'-webkit-right',paddingRight:'5vw',alignSelf:'center'}}>
      {val.isActive?  <i style={{color:'green',fontSize:18}} class="fas fa-check-circle"></i>:null}
          </div>}   
          
            </AccordionSummary>
         <AccordionDetails>
         {/* <Container style={{padding:'2vh 0px'}} > */}
   
  <Grid container style={{padding:'2vh 0px',marginBottom:'2vh'}} key={idx}>

  <Grid item sm={12} xs={12}>

<Typography  style={{fontSize:21,fontWeight:'600',color:'#48a3b8',textAlign:'right'}}>
<i style={{color:'black',fontSize:18}}  onClick={()=>Edit(val)} class="fas fa-edit"></i>
 
 </Typography> 
</Grid> 
   <Grid item sm={12} xs={12}>

      <Typography  style={{fontSize:21,fontWeight:'600',color:'#48a3b8'}}>
      {val.type}
       
       </Typography> 
   </Grid> 
   <Grid item sm={12}  xs={12}>
   <Typography style={{fontSize:19,fontWeight:'400'}}>
   {val.script}

       
       </Typography> 


   </Grid> 
   {/* <Grid item sm={12}  xs={12}>
   <Typography style={{fontSize:19,fontWeight:'400'}}>
   Active : {val.isActive?'true':"false"}

       
       </Typography> 


   </Grid>  */}





</Grid>  
           
                {/* </Container> */}
         
         </AccordionDetails>
       </Accordion>):null}

       </Grid>
    </div>}

             </Grid> 

  )
}
