import React from 'react'
import './PrivacyPolicy.css';

import {useSelector} from 'react-redux';
import {CircularProgress, Grid} from '@mui/material';
import Navbar from '../header/navbar';
import { useGetPrivacyQuery } from '../slices/privacySlice';
import { Site } from '../constants';


const Privacy_policy = () => {
    const {data,isFetching,refetch}=useGetPrivacyQuery(Site)

    const siteVal=useSelector(state=>state.site)
    return (
        
        <>
        <Navbar  img={siteVal.site.header} />
 
        {/* <div className="PP_Block">
        <Grid container xs={12} className="PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid1">
                <h3 style={{color:'#48a3b8'}} className="PrivacyPolicy_Block_MH PrivacyPolicy_Block_H">
            Privacy Policy 
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
                <p className="PrivacyPolicy_Block_P1 PrivacyPolicy_Block_P">
               
                This Privacy Policy describes how ("we," "us," or "our") collects, uses, and shares personal information when you use www.{siteVal.site.name}          </p><br/>

                <h2 style={{color:'#48a3b8'}}>Collection of Information </h2>
                We collect information that you provide directly to us when you use our Site. This may include: 
                &#x2022; Personal information such as name, email address, phone number, and any other information you choose to provide when filling out forms on the Site.  <br /> <br />

                &#x2022;Information is automatically collected through cookies and other tracking technologies. This may include your IP address, browser type, operating system, referring URLs, and other usage information. <br /> <br />



            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
                <h2 style={{color:'#48a3b8'}}>Use of Information  </h2>
                We may use the information we collect for various purposes, including: <br /> <br />
                
                &#x2022; Providing and maintaining our services to our clients.    <br /> <br />
                &#x2022; Responding to your inquiries and requests.   <br /> <br />
                &#x2022; Sending you marketing communications about our services, promotions, and updates.    <br /> <br />
                &#x2022; Analysing trends and usage of the Site.   <br /> <br />


            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>Sharing of Information  </h2>
    We may share your personal information with third parties in the following circumstances: <br /> <br />
    &#x2022; With our clients for whom we are collecting leads on behalf of. <br /> <br />

    &#x2022; With service providers who assist us in operating the Site and providing our services.  <br /> <br />
    &#x2022; With law enforcement or government agencies when required by law or to protect our rights.   <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>Your Choices  </h2>

    You can opt out of receiving marketing communications from us by following the instructions provided in such communications. <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>Data Security  </h2>

    We take reasonable measures to protect the security of your personal information. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.    <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>Changes to this Privacy Policy    </h2>

    We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised policy will become effective when posted.  <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>Contact Us   </h2>

    If you have any questions or concerns about this Privacy Policy, please contact us at [contact@{siteVal.site.name}].  <br /> <br />

</Grid>

           
       
       </Grid> </div> */}


{isFetching?
        <div style={{alignContent:'center',alignItems:'center',paddingTop:'10vh',textAlign:'-webkit-center'}}>
    <CircularProgress />  </div>
        :
        
        
        
       (data? <div className="PP_Block">
       

       <div className="PrivacyPolicy_Block_Grid" dangerouslySetInnerHTML={{__html:data.privacy}}>

       </div>
       
       
       
       </div>:
    window.location.href='/'
    
    )}
       </>
    

    )
}

export default Privacy_policy
