import { apiSlice } from "./apiSlice";

const privacySlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
      getPrivacy:builder.query({
        query:(data)=>({
            url:`privacy/${data}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      
      createPrivacy:builder.mutation({
        query:(data)=>({
            method:'POST',
            body:data,
            url:`privacy/${Date.toString()}`
        })
      }),
      editPrivacy:builder.mutation({
        query:(data)=>({
            method:'PUT',
            body:data,
            url:`privacy/${data.id}`
        })
      }),
     
   
    })
})


export const {useGetPrivacyQuery,useCreatePrivacyMutation,useEditPrivacyMutation}=privacySlice