import { Site } from "../constants";
import { apiSlice } from "./apiSlice";

const termSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
      getTerm:builder.query({
        query:(data)=>({
            url:`terms/${data}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      
      createTerm:builder.mutation({
        query:(data)=>({
            method:'POST',
            body:data,
            url:`terms/${Date.toString()}`
        })
      }),
      editTerm:builder.mutation({
        query:(data)=>({
            method:'PUT',
            body:data,
            url:`terms/${data.id}`
        })
      }),
     
   
    })
})


export const {useGetTermQuery,useCreateTermMutation,useEditTermMutation}=termSlice